import React from 'react';
import {
  AdvancedSearchButton,
  Box,
  Button,
  ComboBox,
  ComboBoxOptionType,
  Drawer,
  Flex,
  Grid,
  Input
} from '@itandi/itandi-bb-ui';
import { FormProvider, useForm } from 'react-hook-form';
import { RiAddBoxLine, RiSearchLine } from 'react-icons/ri';
import { useRouter } from 'next/router';
import { DealSearchCondition, searchDetailBadge } from '~/models/Deal';
import { CompanyVendor, convertToComboBoxOption } from '~/models/CompanyVendor';
import Styles from './DealSearch.module.scss';
import { useDebounce } from '~/hooks/useDebounce';
import { DealSearchDetailTab } from '~/components/specifics/deals/index/search/DealSearchDetailTab';
import { PATH as NEW_PATH } from '~/pages/deals/new';

type Props = {
  dealSearchCondition: DealSearchCondition;
  handleChangeCondition: (newCondition: DealSearchCondition) => Promise<void>;
  companyVendors: CompanyVendor[];
  isSp: boolean;
};

export const DealSearch: React.FC<Props> = ({
  dealSearchCondition,
  handleChangeCondition,
  companyVendors,
  isSp
}: Props) => {
  const methods = useForm<DealSearchCondition>({
    mode: 'onChange',
    defaultValues: dealSearchCondition
  });
  const router = useRouter();
  const [searchName, setSearchName] = React.useState<string>(
    dealSearchCondition.name || ''
  );
  const [isOpenSearchDetailTab, setIsOpenSearchDetailTab] =
    React.useState(false);
  const selectedCompanyVendor =
    companyVendors.find(
      ({ id }) => id === dealSearchCondition.companyVendorId
    ) || null;
  const [selectedCompanyVendorOption, setSelectedCompanyVendorOption] =
    React.useState<ComboBoxOptionType | null>(
      selectedCompanyVendor
        ? convertToComboBoxOption(selectedCompanyVendor)
        : null
    );
  const handleOpenSearchDetailTab = React.useCallback((): void => {
    setIsOpenSearchDetailTab(true);
  }, []);
  const handleCloseSearchDetailTab = React.useCallback((): void => {
    setIsOpenSearchDetailTab(false);
  }, []);
  const debounce = useDebounce(200);
  const onChangeName = React.useCallback(
    async (e: React.ChangeEvent<HTMLInputElement>): Promise<void> => {
      const newCondition: DealSearchCondition = {
        ...dealSearchCondition,
        name: e.target.value
      };
      setSearchName(e.target.value);
      methods.setValue('name', e.target.value);
      debounce(() => {
        handleChangeCondition(newCondition).then();
      });
    },
    [dealSearchCondition, debounce, handleChangeCondition, methods]
  );
  const onChangeCompanyVendor = React.useCallback(
    async (option: ComboBoxOptionType | null): Promise<void> => {
      setSelectedCompanyVendorOption(option);
      const newCondition: DealSearchCondition = {
        ...dealSearchCondition,
        companyVendorId: option?.value ? Number(option.value) : undefined
      };
      methods.setValue(
        'companyVendorId',
        option?.value ? Number(option.value) : undefined
      );
      await handleChangeCondition(newCondition);
    },
    [dealSearchCondition, handleChangeCondition, methods]
  );
  const handleDetailSearch = React.useCallback(async (): Promise<void> => {
    const newCondition = methods.getValues();
    // 簡易検索の入力欄に詳細検索で入力した値を適応する
    setSearchName(newCondition.name || '');
    const newSelectedCompanyVendor =
      companyVendors.find(
        (companyVendor) => companyVendor.id === newCondition.companyVendorId
      ) || null;
    setSelectedCompanyVendorOption(
      newSelectedCompanyVendor
        ? convertToComboBoxOption(newSelectedCompanyVendor)
        : null
    );
    handleCloseSearchDetailTab();
    await handleChangeCondition(newCondition);
  }, [
    methods,
    companyVendors,
    handleCloseSearchDetailTab,
    handleChangeCondition
  ]);
  return (
    <div className={Styles.SimpleSearchContainer}>
      <FormProvider {...methods}>
        <Grid
          alignItems="center"
          autoFlow="column"
          gap={4}
          justifyContent="space-between"
          marginBottom={8}
        >
          <Box width={isSp ? 343 : 480}>
            <Flex alignItems="center" gap={4}>
              <Box width={isSp ? 227 : 180}>
                <Input
                  Icon={RiSearchLine}
                  onChange={onChangeName}
                  placeholder="案件名"
                  value={searchName}
                />
              </Box>
              {!isSp && (
                <Box width={180}>
                  <ComboBox
                    defaultValue={selectedCompanyVendorOption || null}
                    isMulti={false}
                    onChange={onChangeCompanyVendor}
                    options={companyVendors.map((c: CompanyVendor) =>
                      convertToComboBoxOption(c)
                    )}
                    placeholder="施工会社"
                    value={selectedCompanyVendorOption}
                  />
                </Box>
              )}
              <Box width={112}>
                <AdvancedSearchButton
                  badge={searchDetailBadge(dealSearchCondition)}
                  onClick={handleOpenSearchDetailTab}
                />
              </Box>
            </Flex>
          </Box>
          <Box>
            <Button
              onClick={(): void => {
                router.push(NEW_PATH).then();
              }}
              startIcon={<RiAddBoxLine />}
              variant="primary"
            >
              追加
            </Button>
          </Box>
        </Grid>
        <div className={Styles.SearchDetailContainer}>
          <Drawer
            buttonLabel="検索"
            isOpen={isOpenSearchDetailTab}
            onClickBackdrop={handleCloseSearchDetailTab}
            onClickBottomButton={handleDetailSearch}
            onClickCloseButton={handleCloseSearchDetailTab}
            position="Right"
            title="詳細検索"
          >
            <DealSearchDetailTab
              companyVendors={companyVendors}
              dealSearchCondition={dealSearchCondition}
              selectedCompanyVendor={selectedCompanyVendor}
            />
          </Drawer>
        </div>
      </FormProvider>
    </div>
  );
};
