import React from 'react';
import SettingStyles from '../SettingStyle.module.scss';
import { DefaultV2 } from '~/components/common/layouts/DefaultV2';
import { ClientSettingLayout } from '~/components/common/layouts/settings/ClientSettingLayout';
import { CompanyStoreSettingForm } from '~/components/specifics/settings/company_store_settings/CompanyStoreSettingForm';
import useItandiAccount from '~/hooks/useItandiAccount';

export const Page: React.FC = () => {
  const { data: session } = useItandiAccount().currentAccount();
  if (!session?.isAdmin) return null;
  return (
    <DefaultV2>
      <ClientSettingLayout>
        <h2 className={SettingStyles.SettingTitle}>店舗情報設定</h2>
        {session !== null && session.store && (
          <CompanyStoreSettingForm companyStoreSetting={session.store} />
        )}
      </ClientSettingLayout>
    </DefaultV2>
  );
};
