import React, { useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { InputCheckBox, Box } from '@itandi/itandi-bb-ui';
import { DealSearchCondition, DealType } from '~/models/Deal';

type Props = {
  labelText: string;
  dealType: DealType;
  dealSearchCondition: DealSearchCondition;
};

export const DealTypeCheckBox: React.FC<Props> = ({
  dealType,
  labelText,
  dealSearchCondition
}: Props) => {
  const { setValue, getValues } = useFormContext<DealSearchCondition>();
  const { dealTypes: dealTypesFromParam } = dealSearchCondition;
  const defaultCheck = (): boolean => {
    if (dealTypesFromParam) {
      if (['restoration', 'repair', 'other'].includes(dealType)) {
        return dealTypesFromParam.includes(dealType);
      }
    }
    return false;
  };
  const [checked, setChecked] = useState<boolean>(defaultCheck);
  const onChangeDealType = React.useCallback((): void => {
    const dealTypes = getValues('dealTypes') || [];
    if (!checked) {
      // 追加
      if (!dealTypes.includes(dealType)) {
        dealTypes.push(dealType);
      }
    } else if (dealTypes.includes(dealType)) {
      // 削除
      dealTypes.splice(dealTypes.indexOf(dealType), 1);
    }
    setValue('dealTypes', dealTypes);
    setChecked(!checked);
  }, [checked, dealType, getValues, setValue]);
  return (
    <Box margin={4} minWidth={0}>
      <InputCheckBox
        checked={checked}
        labelText={labelText}
        onChange={onChangeDealType}
      />
    </Box>
  );
};
