import React from 'react';
import { useRouter } from 'next/router';
import { useSessions } from '~/hooks/vendors/useSessions';
import { Page as Component } from '~/components/specifics/vendors/deals/index/Page';

export const Page: React.FC = () => {
  const router = useRouter();
  const { isLoading } = useSessions().index();
  if (isLoading || !router.isReady) {
    return <></>;
  }
  return <Component />;
};
