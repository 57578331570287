import React from 'react';
import { useDealEstimations } from '~/hooks/deals/useDealEstimations';
import { PATH as ESTIMATION_EDIT_PATH } from '~/pages/deals/[id]/estimations/edit';
import Styles from './statuses.module.scss';
import { useDeals } from '~/hooks/useDeals';
import { ConstructionNavigation } from '~/components/common/constructions/navigation/ConstructionNavigation';
import { PATH as INSPECTION_REPORT_PATH } from '~/pages/deals/[id]/inspection_reports';
import { PATH as COMPLETE_REPORT_PATH } from '~/pages/deals/[id]/completion_reports';
import { matchDealType } from '~/models/Deal';
import { update as updateDealInspection } from '~/utils/api/DealInspection';
import { useIdQueryHandler } from '~/hooks/useIdQueryHandler';
import { ReportImageNavigation } from '~/components/common/reports/navigation/ReportImageNavigation';
import { useDealInspectionReportImages } from '~/hooks/deals/useDealInspectionReportImages';
import { useDealCompletionReportImages } from '~/hooks/deals/useDealCompletionReportImages';
import { navigationTitle } from '~/models/DealReportImage';
import { OwnStatusHandleButtons } from '~/components/common/statuses/OwnStatusHandleButtons';
import {
  OWN_IN_ESTIMATION,
  OWN_SEND_ESTIMATE,
  ownStatusMovingValidation
} from '~/models/Status';
import useItandiAccount from '~/hooks/useItandiAccount';
import { ScheduleInputNavigation } from '~/components/common/inspection_schedule/ScheduleInputNavigation';
import { NskResponse } from '~/models/Api';

export const OwnInEstimation: React.FC = () => {
  const dealId = useIdQueryHandler();
  const { data: deal, mutate: mutateDeal } = useDeals().show(dealId);
  const { data: account } = useItandiAccount().currentAccount();
  const { data: estimationContext } = useDealEstimations().index(dealId);
  const { data: inspectionImages } =
    useDealInspectionReportImages().index(dealId);
  const { data: completeImages } =
    useDealCompletionReportImages().index(dealId);
  const statusDisabled = React.useMemo(() => {
    if (account === null) return true;
    return !ownStatusMovingValidation[OWN_IN_ESTIMATION](account);
  }, [account]);
  if (deal === null || inspectionImages === null || completeImages === null) {
    return null;
  }
  const handleMutateDeal = async (): Promise<void> => {
    mutateDeal && (await mutateDeal());
  };
  const handleUpdateDealInspection = async (
    inspectionDate: Date
  ): Promise<NskResponse<void>> =>
    updateDealInspection(deal.id, inspectionDate);
  return (
    <div>
      <h3 className={Styles.Title}>
        {matchDealType(deal, 'restoration') && '退去手続きの'}見積り作成中です
      </h3>
      <p className={Styles.Description}>
        立会が完了して、見積り作成後にステータスを進めてください
      </p>
      <ScheduleInputNavigation
        deal={deal}
        handleMutate={handleMutateDeal}
        handleUpdate={handleUpdateDealInspection}
      />
      {estimationContext !== null && (
        <ConstructionNavigation
          dealConstructionContext={estimationContext}
          detailPagePath={ESTIMATION_EDIT_PATH(deal.id)}
          title="見積りを入力してください"
        />
      )}
      <div>
        <ReportImageNavigation
          detailPageButtonLabel="画像登録画面"
          detailPagePath={INSPECTION_REPORT_PATH(deal.id)}
          optionalLabel
          reportImages={inspectionImages}
          title={navigationTitle(deal)}
        />
        <ReportImageNavigation
          detailPageButtonLabel="画像登録画面"
          detailPagePath={COMPLETE_REPORT_PATH(deal.id)}
          optionalLabel
          reportImages={completeImages}
          title="完了報告画像を登録してください"
        />
        <OwnStatusHandleButtons
          backwardDisabled={statusDisabled}
          forwardDisabled={statusDisabled}
          nextStatusId={OWN_SEND_ESTIMATE}
        />
      </div>
    </div>
  );
};
