import React from 'react';
import { toast } from 'react-toastify';
import { StatusHandleButtons } from '~/components/common/statuses/StatusHandleButtons';
import { update as updateStatus } from '~/utils/api/DealStatus';
import { isSuccess } from '~/utils/api/api_handler';
import useLoader from '~/hooks/useLoader';
import { useDeals } from '~/hooks/useDeals';
import { useIdQueryHandler } from '~/hooks/useIdQueryHandler';
import { PARTIALLY_FINISHED } from '~/models/Status';

type Props = {
  nextStatusId: number;
  prevStatusId?: number | null;
  forwardDisabled: boolean;
  backwardDisabled: boolean;
  allowPartiallyFinish?: boolean;
};

export const OwnStatusHandleButtons: React.FC<Props> = ({
  nextStatusId,
  prevStatusId = null,
  forwardDisabled,
  backwardDisabled,
  allowPartiallyFinish = false
}: Props) => {
  const { Loader, showLoader, hideLoader } = useLoader();
  const dealId = useIdQueryHandler();
  const { data: deal, mutate: mutateDeal } = useDeals().show(dealId);
  if (deal === null) return null;
  const handleUpdateStatus = async (statusId: number): Promise<void> => {
    showLoader();
    const result = await updateStatus(deal.id, statusId, null, null);
    if (isSuccess(result)) {
      mutateDeal && (await mutateDeal());
      hideLoader();
      toast.success(`ステータスを更新しました`);
      return;
    }
    hideLoader();
    toast.error('エラーが発生しました');
  };
  const handleForwardStatus = async (): Promise<void> => {
    await handleUpdateStatus(nextStatusId);
  };
  const handleBackwardStatus = async (): Promise<void> => {
    if (prevStatusId === null) return;
    await handleUpdateStatus(prevStatusId);
  };
  const handlePartiallyFinish = async (): Promise<void> => {
    if (!allowPartiallyFinish) return;
    handleUpdateStatus(PARTIALLY_FINISHED);
  };
  return (
    <>
      <Loader />
      <StatusHandleButtons
        allowPartiallyFinish={allowPartiallyFinish}
        backwardDisabled={backwardDisabled}
        canBackward={prevStatusId !== null}
        forwardDisabled={forwardDisabled}
        handleBackwardStatus={handleBackwardStatus}
        handleForwardStatus={handleForwardStatus}
        handlePartiallyFinish={handlePartiallyFinish}
      />
    </>
  );
};
