import React from 'react';
import { ComboBox, ComboBoxOptionType } from '@itandi/itandi-bb-ui';
import { useFormContext } from 'react-hook-form';
import { DealSearchCondition } from '~/models/Deal';
import { useVendorPartners } from '~/hooks/vendors/useVendorPartners';
import { VendorPartner, convertToComboBoxOption } from '~/models/VendorPartner';

type Props = {
  dealSearchCondition: DealSearchCondition;
};

export const VendorPartnerCombobox: React.FC<Props> = ({
  dealSearchCondition
}: Props) => {
  const { data: vendorPartners } = useVendorPartners().index();
  const { setValue } = useFormContext<DealSearchCondition>();
  const noVendorPartnerOption: ComboBoxOptionType = {
    label: '実施会社未指定',
    value: String(-1)
  };
  const vendorPartnerOptions: ComboBoxOptionType[] = [
    noVendorPartnerOption
  ].concat(
    vendorPartners?.map((vp: VendorPartner) => convertToComboBoxOption(vp)) ||
      []
  );
  const [selectedVendorPartnerOption, setSelectedVendorPartnerOption] =
    React.useState<ComboBoxOptionType | null>(
      vendorPartnerOptions.find(
        ({ value }) => value === String(dealSearchCondition.partnerVendorId)
      ) || null
    );
  const onChangeVendorPartner = React.useCallback(
    async (option: ComboBoxOptionType | null): Promise<void> => {
      setSelectedVendorPartnerOption(option);
      setValue('partnerVendorId', option ? Number(option.value) : undefined);
    },
    [setValue]
  );
  if (vendorPartners === null) {
    return null;
  }
  return (
    <>
      <ComboBox
        defaultValue={selectedVendorPartnerOption}
        isMulti={false}
        onChange={onChangeVendorPartner}
        options={vendorPartnerOptions}
        placeholder="実施会社"
        value={selectedVendorPartnerOption}
      />
    </>
  );
};
