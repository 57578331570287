import React from 'react';
import { Alert, Button } from '@itandi/itandi-bb-ui';
import Styles from './StatusHandleButtons.module.scss';
import { ConfirmModal } from '~/components/common/parts/modal/ConfirmModal';
import { useOpenHandler } from '~/hooks/useOpenHandler';

type Props = {
  canBackward: boolean;
  handleForwardStatus: () => Promise<void>;
  handleBackwardStatus: () => Promise<void>;
  forwardDisabled: boolean;
  backwardDisabled: boolean;
  allowPartiallyFinish?: boolean;
  handlePartiallyFinish?: (() => Promise<void>) | null;
};

export const StatusHandleButtons: React.FC<Props> = ({
  canBackward,
  handleForwardStatus,
  handleBackwardStatus,
  forwardDisabled,
  backwardDisabled,
  allowPartiallyFinish = false,
  handlePartiallyFinish = null
}: Props) => {
  const {
    isOpen: openConfirm,
    handleOpen: handleOpenConfirm,
    handleClose: handleCloseConfirm
  } = useOpenHandler();
  const {
    isOpen: openBackConfirm,
    handleOpen: handleOpenBackConfirm,
    handleClose: handleCloseBackConfirm
  } = useOpenHandler();
  const {
    isOpen: openPartiallyFinishConfirm,
    handleOpen: handleOpenPartiallyFinishConfirm,
    handleClose: handleClosePartiallyFinishConfirm
  } = useOpenHandler();
  const handleAcceptForward = async (): Promise<void> => {
    await handleForwardStatus();
    handleCloseConfirm();
  };
  const handleAcceptBackward = async (): Promise<void> => {
    await handleBackwardStatus();
    handleCloseBackConfirm();
  };
  const handleAcceptPartiallyFinish = async (): Promise<void> => {
    if (handlePartiallyFinish === null) return;
    await handlePartiallyFinish();
  };
  return (
    <div className={Styles.StatusHandleButtonRow}>
      {allowPartiallyFinish && (
        <Button
          disabled={forwardDisabled}
          height="default"
          onClick={handleOpenPartiallyFinishConfirm}
          variant="tertiary"
        >
          工事を依頼せずに途中完了する
        </Button>
      )}
      <div className={Styles.RightButtons}>
        {canBackward && (
          <button
            className={Styles.BackwardButton}
            disabled={backwardDisabled}
            onClick={handleOpenBackConfirm}
            type="button"
          >
            ステータスを戻す
          </button>
        )}
        <button
          className={Styles.ForwardButton}
          disabled={forwardDisabled}
          onClick={handleOpenConfirm}
          type="button"
        >
          ステータスを進める
        </button>
      </div>
      <ConfirmModal
        actionText="確定"
        confirmType="save"
        handleAccept={handleAcceptPartiallyFinish}
        handleCancel={handleClosePartiallyFinishConfirm}
        isOpen={openPartiallyFinishConfirm}
      >
        <p className={Styles.ConfirmText}>
          案件を完了扱いにしてよろしいですか？
        </p>
        <Alert variant="warning">
          施工会社には工事が依頼されていない状態で完了扱いになります。
        </Alert>
      </ConfirmModal>
      <ConfirmModal
        actionText="確定"
        confirmType="save"
        handleAccept={handleAcceptForward}
        handleCancel={handleCloseConfirm}
        isOpen={openConfirm}
      >
        ステータスを進めてよろしいですか？
      </ConfirmModal>
      <ConfirmModal
        actionText="確定"
        confirmType="delete"
        handleAccept={handleAcceptBackward}
        handleCancel={handleCloseBackConfirm}
        isOpen={openBackConfirm}
      >
        ステータスを戻してよろしいですか？
      </ConfirmModal>
    </div>
  );
};
