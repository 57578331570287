import React from 'react';
import {
  AdvancedSearchButton,
  Box,
  Drawer,
  Flex,
  Grid,
  Input
} from '@itandi/itandi-bb-ui';
import { FormProvider, useForm } from 'react-hook-form';
import { RiSearchLine } from 'react-icons/ri';
import { DealSearchCondition, searchDetailBadge } from '~/models/Deal';
import Styles from './DealSearch.module.scss';
import { useDebounce } from '~/hooks/useDebounce';
import { DealSearchDetailTab } from './DealSearchDetailTab';

import { MaintenanceCompanyCombobox } from '~/components/specifics/vendors/deals/index/search/detail/MaintenanceCompanyCombobox';
import { useCompanySettings } from '~/hooks/vendors/useCompanySettings';

type Props = {
  dealSearchCondition: DealSearchCondition;
  handleChangeCondition: (newCondition: DealSearchCondition) => Promise<void>;
  isSp: boolean;
};

export const DealSearch: React.FC<Props> = ({
  dealSearchCondition,
  handleChangeCondition,
  isSp
}: Props) => {
  const methods = useForm<DealSearchCondition>({
    mode: 'onChange',
    defaultValues: dealSearchCondition
  });
  const [searchName, setSearchName] = React.useState<string>(
    dealSearchCondition.name || ''
  );
  const [searchCompanyName, setSearchCompanyName] = React.useState<string>('');
  const { data: maintenanceCompanies } = useCompanySettings().index(
    searchCompanyName,
    null
  );
  const [isOpenSearchDetailTab, setIsOpenSearchDetailTab] =
    React.useState(false);
  const [selectedCompanyMasterId, setSelectedCompanyMasterId] = React.useState(
    dealSearchCondition.companyMasterId
  );
  const handleOpenSearchDetailTab = React.useCallback((): void => {
    setIsOpenSearchDetailTab(true);
  }, []);
  const handleCloseSearchDetailTab = React.useCallback((): void => {
    setIsOpenSearchDetailTab(false);
  }, []);
  const debounce = useDebounce(200);
  const onChangeName = React.useCallback(
    async (e: React.ChangeEvent<HTMLInputElement>): Promise<void> => {
      const newCondition: DealSearchCondition = {
        ...dealSearchCondition,
        name: e.target.value
      };
      setSearchName(e.target.value);
      methods.setValue('name', e.target.value);
      debounce(() => {
        handleChangeCondition(newCondition).then();
      });
    },
    [dealSearchCondition, debounce, handleChangeCondition, methods]
  );
  const handleChangeMaintenanceCompanyInput = React.useCallback(
    (inputText: string): void => {
      debounce(() => {
        setSearchCompanyName(inputText);
      });
    },
    [debounce]
  );
  const onChangeCompanyMasterId = React.useCallback(
    async (companyMasterId: number | undefined): Promise<void> => {
      setSelectedCompanyMasterId(companyMasterId);
      const newCondition: DealSearchCondition = {
        ...dealSearchCondition,
        companyMasterId
      };
      methods.setValue('companyMasterId', companyMasterId);
      await handleChangeCondition(newCondition);
    },
    [dealSearchCondition, handleChangeCondition, methods]
  );
  const handleDetailSearch = React.useCallback(async (): Promise<void> => {
    const newCondition = methods.getValues();
    // 簡易検索の入力欄に詳細検索で入力した値を適応する
    setSearchName(newCondition.name || '');
    setSelectedCompanyMasterId(newCondition.companyMasterId);
    handleCloseSearchDetailTab();
    await handleChangeCondition(newCondition);
  }, [methods, handleCloseSearchDetailTab, handleChangeCondition]);
  return (
    <div className={Styles.SimpleSearchContainer}>
      <FormProvider {...methods}>
        <Grid
          alignItems="center"
          autoFlow="column"
          gap={4}
          justifyContent="space-between"
          marginBottom={8}
        >
          <Box width={isSp ? 343 : 480}>
            <Flex alignItems="center" gap={4}>
              <Box width={isSp ? 227 : 180}>
                <Input
                  Icon={RiSearchLine}
                  onChange={onChangeName}
                  placeholder="案件名"
                  value={searchName}
                />
              </Box>
              {!isSp && (
                <Box width={180}>
                  <MaintenanceCompanyCombobox
                    handleChangeMaintenanceCompanyInput={
                      handleChangeMaintenanceCompanyInput
                    }
                    maintenanceCompanies={maintenanceCompanies?.objects || null}
                    onChangeCompanyMasterId={onChangeCompanyMasterId}
                    selectedCompanyMasterId={selectedCompanyMasterId}
                  />
                </Box>
              )}
              <Box width={112}>
                <AdvancedSearchButton
                  badge={searchDetailBadge(dealSearchCondition)}
                  onClick={handleOpenSearchDetailTab}
                />
              </Box>
            </Flex>
          </Box>
        </Grid>
        <div className={Styles.SearchDetailContainer}>
          <Drawer
            buttonLabel="検索"
            isOpen={isOpenSearchDetailTab}
            onClickBackdrop={handleCloseSearchDetailTab}
            onClickBottomButton={handleDetailSearch}
            onClickCloseButton={handleCloseSearchDetailTab}
            position="Right"
            title="詳細検索"
          >
            <DealSearchDetailTab
              dealSearchCondition={dealSearchCondition}
              handleChangeMaintenanceCompanyInput={
                handleChangeMaintenanceCompanyInput
              }
              maintenanceCompanies={maintenanceCompanies?.objects || null}
            />
          </Drawer>
        </div>
      </FormProvider>
    </div>
  );
};
