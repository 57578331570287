import React from 'react';
import Styles from './statuses.module.scss';
import { useDealEstimations } from '~/hooks/deals/useDealEstimations';
import { useDealInspectionReportImages } from '~/hooks/deals/useDealInspectionReportImages';
import { ReportImageNavigation } from '~/components/common/reports/navigation/ReportImageNavigation';
import { PATH as INSPECTION_REPORT_PATH } from '~/pages/deals/[id]/inspection_reports';
import { ConstructionNavigation } from '~/components/common/constructions/navigation/ConstructionNavigation';
import { PATH as ESTIMATION_PATH } from '~/pages/deals/[id]/estimations';
import { UploadFileNavigation } from '~/components/common/upload_files/UploadFileNavigation';
import {
  OWN_IN_CONSTRUCTION,
  OWN_IN_ESTIMATION,
  OWN_SEND_ESTIMATE,
  ownStatusMovingValidation
} from '~/models/Status';
import { DealUploadFile } from '~/models/DealUploadFile';
import { getFilePath as getClientFilePath } from '~/utils/api/deals/DealUploadedFile';
import { useDeals } from '~/hooks/useDeals';
import { matchDealType } from '~/models/Deal';
import { navigationTitle } from '~/models/DealReportImage';
import { useIdQueryHandler } from '~/hooks/useIdQueryHandler';
import useItandiAccount from '~/hooks/useItandiAccount';
import { OwnStatusHandleButtons } from '~/components/common/statuses/OwnStatusHandleButtons';

export const OwnSendEstimate: React.FC = () => {
  const id = useIdQueryHandler();
  const { data: deal } = useDeals().show(id);
  const { data: dealEstimationContext } = useDealEstimations().index(id);
  const { data: reportImages } = useDealInspectionReportImages().index(id);
  const { data: account } = useItandiAccount().currentAccount();
  const statusDisabled = React.useMemo(() => {
    if (account === null) return true;
    return !ownStatusMovingValidation[OWN_SEND_ESTIMATE](account);
  }, [account]);
  if (deal === null || id === null) {
    return null;
  }
  const handleGetMemorandumFilePath = (file: DealUploadFile): string =>
    getClientFilePath(id, file);
  return (
    <div>
      <h3 className={Styles.Title}>
        {`${
          matchDealType(deal, 'restoration') ? '退去' : ''
        }立会が完了しました`}
      </h3>
      {reportImages !== null && (
        <ReportImageNavigation
          detailPageButtonLabel="画像確認画面"
          detailPagePath={INSPECTION_REPORT_PATH(id)}
          reportImages={reportImages}
          title={navigationTitle(deal)}
        />
      )}
      {dealEstimationContext !== null && (
        <ConstructionNavigation
          dealConstructionContext={dealEstimationContext}
          detailPagePath={ESTIMATION_PATH(id)}
          title="見積りを確認してください"
        />
      )}
      {deal.dealMemorandumFiles.length > 0 && (
        <UploadFileNavigation
          deal={deal}
          files={deal.dealMemorandumFiles}
          fileType="賃借人同意書"
          handleGetFilePath={handleGetMemorandumFilePath}
          title="賃借人同意書を確認してください"
        />
      )}
      <OwnStatusHandleButtons
        allowPartiallyFinish
        backwardDisabled={statusDisabled}
        forwardDisabled={statusDisabled}
        nextStatusId={OWN_IN_CONSTRUCTION}
        prevStatusId={OWN_IN_ESTIMATION}
      />
    </div>
  );
};
