import React from 'react';
import {
  Td,
  Tr,
  Chip,
  Text,
  TextLink,
  colors,
  Flex,
  Box
} from '@itandi/itandi-bb-ui';
import { DealIndexVendor } from '~/models/Deal';
import { DealTypeChip } from '~/components/common/parts/chip/DealTypeChip';
import { truncate } from '~/utils/StringUtil';
import { PATH as VENDOR_DEAL_PATH } from '~/pages/vendors/deals/[id]';

type Props = {
  deal: DealIndexVendor;
  statusNames: { [p: string]: string };
};

export const DealTableSPRow: React.FC<Props> = ({
  deal,
  statusNames
}: Props) => (
  <Tr>
    <Td textAlign="Left" verticalAlign="Top">
      <Box marginY={2}>
        <Chip label={statusNames[deal.status]} />
      </Box>
      {deal.unreadMessageCount > 0 && (
        <Box marginY={2}>
          <Chip
            background={colors['semantic-negative']}
            borderColor={null}
            label={`未読${deal.unreadMessageCount}`}
            textColor={colors['itandi-white']}
          />
        </Box>
      )}
    </Td>
    <Td textAlign="Left" verticalAlign="Top">
      <Box>
        <Flex gap={2}>
          <DealTypeChip
            dealSubtype={deal.dealSubtype}
            dealType={deal.dealType}
          />
        </Flex>
      </Box>
      <Box>
        <TextLink
          href={VENDOR_DEAL_PATH(deal.id)}
          target="_blank"
          variant="PrimaryBold"
        >
          {`${truncate(deal.name, 30)} ${deal.roomNumber || ''}`}
        </TextLink>
        <Flex gap={4}>
          <Box>
            <Text color="Sub" display="Block" size="S">
              {truncate(deal.address, 35)}
            </Text>
          </Box>
          {deal.tenantName && (
            <Box>
              <Text color="Sub" display="Block" size="S">
                {`${truncate(deal.tenantName, 15)} 様`}
              </Text>
            </Box>
          )}
        </Flex>
      </Box>
    </Td>
  </Tr>
);
