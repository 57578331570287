import React from 'react';
import Styles from './statuses.module.scss';
import {
  OWN_AWAITING_ACCEPTANCE,
  OWN_FINISHED,
  OWN_IN_CONSTRUCTION,
  ownStatusMovingValidation
} from '~/models/Status';
import { useDealCompletionReportImages } from '~/hooks/deals/useDealCompletionReportImages';
import { ReportImageNavigation } from '~/components/common/reports/navigation/ReportImageNavigation';
import { PATH as COMPLETION_REPORT_PATH } from '~/pages/deals/[id]/completion_reports';
import { useDeals } from '~/hooks/useDeals';
import { useIdQueryHandler } from '~/hooks/useIdQueryHandler';
import useItandiAccount from '~/hooks/useItandiAccount';
import { OwnStatusHandleButtons } from '~/components/common/statuses/OwnStatusHandleButtons';

export const OwnAwaitingAcceptance: React.FC = () => {
  const dealId = useIdQueryHandler();
  const { data: deal } = useDeals().show(dealId);
  const { data: account } = useItandiAccount().currentAccount();
  const { data: reportImages } = useDealCompletionReportImages().index(dealId);
  const statusDisabled = React.useMemo(() => {
    if (account === null) return true;
    return !ownStatusMovingValidation[OWN_AWAITING_ACCEPTANCE](account);
  }, [account]);
  if (deal === null) {
    return null;
  }
  return (
    <div>
      <h3 className={Styles.Title}>工事が完了しました</h3>
      <p className={Styles.Description}>
        完了報告画像を確認いただき、問題なければステータスを進めてください。
      </p>
      {reportImages !== null && (
        <ReportImageNavigation
          detailPageButtonLabel="画像確認画面"
          detailPagePath={COMPLETION_REPORT_PATH(deal.id)}
          reportImages={reportImages}
          title="完了報告画像を確認してください"
        />
      )}
      <OwnStatusHandleButtons
        backwardDisabled={statusDisabled}
        forwardDisabled={statusDisabled}
        nextStatusId={OWN_FINISHED}
        prevStatusId={OWN_IN_CONSTRUCTION}
      />
    </div>
  );
};
