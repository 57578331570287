import React from 'react';
import { useFormContext } from 'react-hook-form';
import {
  Input,
  InputDate,
  InputSelect,
  InputText,
  ItandiChip
} from '@itandi/itandi-bb-ui';
import { RiInformationLine } from 'react-icons/ri';
import Styles from './Block.module.scss';
import { toDate } from '~/utils/DateUtil';
import { DealUpdateParam } from '~/models/DealForm';
import { onChangeDate } from '~/utils/FormUtil';
import { useStaffs } from '~/hooks/useStaffs';
import { Staff } from '~/models/Staff';
import { Deal, matchDealType } from '~/models/Deal';

type Props = {
  deal: Deal;
};

export const DealBlock: React.FC<Props> = ({ deal }: Props) => {
  const { data: staffs } = useStaffs().index();
  const { register, watch, setValue, formState } =
    useFormContext<DealUpdateParam>();
  const { errors } = formState;
  const onChangeStaff = React.useCallback(
    (e: React.ChangeEvent<HTMLSelectElement>): void => {
      const { value } = e.target;
      if (staffs !== null) {
        const selectStaff =
          staffs.find((s: Staff) => s.id === Number(value)) ?? null;
        setValue('staff', selectStaff);
      }
    },
    [staffs, setValue]
  );
  const staff = watch('staff');
  const terminationDate = watch('terminationDate');
  if (deal === null) return null;
  return (
    <div>
      <div className={Styles.FormRow}>
        <div className={Styles.FormRowLabel}>
          <RiInformationLine className={Styles.BlockIcon} />
          その他情報
        </div>
      </div>
      {matchDealType(deal, 'restoration') && (
        <div className={Styles.FormRow}>
          <div className={Styles.FormLabelCol}>
            <span className={Styles.FormLabelText}>解約日</span>
            <span className={Styles.RequiredLabel}>
              <ItandiChip label="任意" />
            </span>
          </div>
          <div className={Styles.FormSettingCol}>
            <div>
              <InputDate
                className={Styles.DatePicker}
                onChange={(date): void => {
                  onChangeDate(date, 'terminationDate', setValue);
                }}
                value={toDate(terminationDate)}
              />
            </div>
          </div>
        </div>
      )}
      <div className={Styles.FormRow}>
        <div className={Styles.FormLabelCol}>
          <span className={Styles.FormLabelText}>案件担当者</span>
          <span className={Styles.RequiredLabel}>
            <ItandiChip label="必須" />
          </span>
        </div>
        <div className={Styles.FormSettingCol}>
          <div>
            <InputSelect
              className={Styles.InputSelect}
              onChange={onChangeStaff}
              value={String(staff?.id ?? '')}
            >
              {staffs !== null &&
                staffs.map((s: Staff) => (
                  <option key={s.id} value={s.id}>
                    {s.name}
                  </option>
                ))}
            </InputSelect>
          </div>
        </div>
      </div>
      <div className={Styles.FormRow}>
        <div className={Styles.FormLabelCol}>
          <span className={Styles.FormLabelText}>発注書請書の宛名</span>
          <span className={Styles.RequiredLabel}>
            <ItandiChip label="必須" />
          </span>
        </div>
        <div className={Styles.FormSettingCol}>
          <div>
            <Input
              isInvalid={errors.estimateAddressName != null}
              placeholder="例）サンプル株式会社"
              {...register('estimateAddressName')}
            />
          </div>
          {errors.estimateAddressName != null && (
            <div className={Styles.InvalidFeedback}>
              {errors.estimateAddressName.message}
            </div>
          )}
          <p className={Styles.Description}>
            発注者が貸主（オーナー）様になる場合は、貸主名を入力してください
            <br />
            内装工事くんおまかせプランの場合には、発注者は原則管理会社となります。
          </p>
        </div>
      </div>
      <div className={Styles.FormRow}>
        <div className={Styles.FormLabelCol}>
          <span className={Styles.FormLabelText}>その他ご要望、特筆事項</span>
          <span className={Styles.RequiredLabel}>
            <ItandiChip label="任意" />
          </span>
        </div>
        <div className={Styles.FormSettingCol}>
          <div>
            <InputText rows={3} {...register('note')} />
          </div>
        </div>
      </div>
    </div>
  );
};
