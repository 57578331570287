import React from 'react';
import { Th, Tr } from '@itandi/itandi-bb-ui';
import { DealSortKey } from '~/models/Deal';

type Props = {
  sortCondition: (sortKey: DealSortKey) => 'asc' | 'desc' | 'none';
  handleSort: (selectedSortKey: DealSortKey) => () => Promise<void>;
};
export const DealTableHeader: React.FC<Props> = ({
  sortCondition,
  handleSort
}: Props) => (
  <Tr>
    <Th
      onSort={handleSort('status_id')}
      sort={sortCondition('status_id')}
      width="6.5rem"
    >
      状況
    </Th>
    <Th>案件</Th>
    <Th textAlign="Center">
      残り
      <br />
      TODO
    </Th>
    <Th>自社担当者</Th>
    <Th>
      施工会社
      <br />
      担当者
    </Th>
    <Th
      onSort={handleSort('termination_date')}
      sort={sortCondition('termination_date')}
      width="5.2rem"
    >
      解約日
    </Th>
    <Th
      onSort={handleSort('inspection_date')}
      sort={sortCondition('inspection_date')}
      width="5.2rem"
    >
      立会日時
    </Th>
    <Th
      onSort={handleSort('construction_start_date')}
      sort={sortCondition('construction_start_date')}
      width="5.2rem"
    >
      工期開始
    </Th>
    <Th
      onSort={handleSort('construction_end_date')}
      sort={sortCondition('construction_end_date')}
      width="5.2rem"
    >
      工期終了
    </Th>
    <Th
      onSort={handleSort('preferred_date')}
      sort={sortCondition('preferred_date')}
      width="5.2rem"
    >
      入居予定日
    </Th>
    <Th
      onSort={handleSort('registration_date')}
      sort={sortCondition('registration_date')}
      width="5.2rem"
    >
      登録日
    </Th>
  </Tr>
);
