import React from 'react';
import { Th, Tr, Box } from '@itandi/itandi-bb-ui';
import { DealSortKey } from '~/models/Deal';

type Props = {
  sortCondition: (sortKey: DealSortKey) => 'asc' | 'desc' | 'none';
  handleSort: (selectedSortKey: DealSortKey) => () => Promise<void>;
};

export const DealTableSPHeader: React.FC<Props> = ({
  sortCondition,
  handleSort
}: Props) => (
  <Tr>
    <Th
      onSort={handleSort('status_id')}
      sort={sortCondition('status_id')}
      width="6.5rem"
    >
      <Box marginY={8}>状況</Box>
    </Th>
    <Th>
      <Box marginY={8}>案件</Box>
    </Th>
  </Tr>
);
