import { object, string } from 'yup';

export type CompanyStoreSetting = {
  authId: number;
  companyMasterId: number;
  companyName: string;
  name: string;
  zipcode: string | null;
  state: string | null;
  city: string | null;
  otherAddress: string | null;
  phoneNumber: string | null;
  masterAccount: boolean;
};

export type CompanyStoreSettingEdit = Omit<
  CompanyStoreSetting,
  'authId' | 'companyMasterId' | 'companyName' | 'masterAccount'
> & {
  robotPaymentStoreCode: string;
};

export const validationSchemaCompanyStoreSetting = object().shape({
  name: string().required('入力してください'),
  zipcode: string()
    .required('入力してください')
    .test('len', '7桁で入力してください', (value: string | undefined | null) =>
      [7].includes((value ?? '').replace(/-/g, '').length)
    ),
  state: string().required('入力してください'),
  city: string().required('入力してください'),
  phoneNumber: string().required('入力してください')
});
