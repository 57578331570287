import React from 'react';
import { DefaultV2 } from '~/components/common/layouts/DefaultV2';
import { DealLayout } from '~/components/common/layouts/deals/DealLayout';
import { useDeals } from '~/hooks/useDeals';
import { BreadcrumbItem } from '~/components/common/parts/breadcrumb/Breadcrumbs';
import { PATH as DEALS_PATH } from '~/pages/deals';
import Loading from '~/components/common/parts/loading/Loading';
import { PATH } from '~/pages/deals/[id]';
import Styles from './Page.module.scss';
import { useDealEstimations } from '~/hooks/deals/useDealEstimations';
import { getDownloadPath } from '~/utils/api/deals/DealEstimationFile';
import { ConstructionTop } from '~/components/common/constructions/ConstructionTop';
import { useConstructionItems } from '~/hooks/useConstructionItems';
import { ConstructionList } from '~/components/common/constructions/list/ConstructionList';
import { update as updateEnabled } from '~/utils/api/deals/DealEstimation';
import { DealConstruction } from '~/models/DealConstruction';
import { OWN_SEND_ESTIMATE, SEND_ESTIMATE } from '~/models/Status';
import { isItandiVendor } from '~/models/VendorCompany';
import { getOriginVendor } from '~/models/DealVendorCompany';
import { useIdQueryHandler } from '~/hooks/useIdQueryHandler';

// 下記ステータスの場合のみ工事対象を変更できる
const allowedStatuses: number[] = [SEND_ESTIMATE, OWN_SEND_ESTIMATE];

export const Page: React.FC = () => {
  const id = useIdQueryHandler();
  const { data: estimationContext, mutate: mutateConstructions } =
    useDealEstimations().index(id);
  const { data: deal } = useDeals().show(id);
  const { data: constructionItems } = useConstructionItems().index();
  const isReadonly = React.useMemo(() => {
    if (deal === null) {
      return true;
    }
    return !allowedStatuses.some((s) => s === deal.status);
  }, [deal]);
  if (
    id === null ||
    deal === null ||
    estimationContext === null ||
    constructionItems === null
  ) {
    return <Loading isLoading />;
  }
  const handleMutateConstructions = async (): Promise<void> => {
    mutateConstructions && (await mutateConstructions());
  };
  const handleClickPreview = (): void => {
    const path = getDownloadPath(id);
    window.open(path);
  };
  const breadcrumbItems: BreadcrumbItem[] = [
    { path: DEALS_PATH, caption: '案件一覧', active: false },
    { path: PATH(deal.id), caption: '案件詳細', active: false },
    { path: '', caption: '見積り確認', active: true }
  ];
  const handleUpdateEnabled = async (
    newConstruction: DealConstruction
  ): Promise<void> => {
    if (newConstruction.type !== 'DealEstimation') {
      return;
    }
    await updateEnabled(newConstruction, newConstruction.id, deal.id);
  };
  return (
    <DefaultV2>
      <DealLayout
        breadcrumbItems={breadcrumbItems}
        deal={deal}
        isVendor={false}
      >
        <h2 className={Styles.EstimateTitle}>見積り確認</h2>
        <ConstructionTop
          constructionContext={estimationContext}
          handleClickPreview={handleClickPreview}
          readonly={isReadonly}
        />
        <ConstructionList
          companyType="client"
          constructionContext={estimationContext}
          constructionItems={constructionItems}
          docType="estimation"
          handleMutateConstructions={handleMutateConstructions}
          handleUpdateEnabled={
            isItandiVendor(deal) || getOriginVendor(deal) === null
              ? handleUpdateEnabled
              : null
          }
          readonly={isReadonly}
        />
      </DealLayout>
    </DefaultV2>
  );
};
